// * UPDATE TITLE AND TAB TITLE * //

//grab elements
const title = document.getElementById("bagenzo");
const tab = document.querySelector("title");
const url = location.href;
const pageName = url.substring(url.lastIndexOf('/') + 1);

//name update logic
const targetName = sessionStorage.getItem('bagenzoName');
if (targetName) {
    //update h1
    title.textContent = `${targetName} Bagenzo`;

    //update tab
    switch (pageName) {
        case '':
            tab.textContent = `${targetName} Bagenzo`;
            break;
        case 'about':
            tab.textContent = `About ${targetName}`;
            break;
        case 'projects':
            tab.textContent = `${targetName}'s Projects`;
            break;
        case 'links':
            tab.textContent = `${targetName}'s Links`;
            break;
        default:
            tab.textContent = `${targetName} Bagenz4o4`;
            break;
    }
}

// * NAME BUTTONS ON ABOUT PAGE * //
if (pageName === 'about') {

    // grab all the elements
    const kate_target = document.getElementById("kate");
    const katie_target = document.getElementById("katie");
    const katey_target = document.getElementById("katey");

    //create anchors to replace spans
    const kate = document.createElement("button");
    const katie = document.createElement("button");
    const katey = document.createElement("button");
    kate.textContent = "kate";
    katie.textContent = "katie";
    katey.textContent = "katey";
    kate.classList.add('pointerButton');
    katie.classList.add('pointerButton');
    katey.classList.add('pointerButton');

    //add onclick behavior to anchors
    kate.addEventListener('click', () => {
        title.textContent = "Kate Bagenzo";
        tab.textContent = "About Kate";

        kate.style.color = "black";
        kate.style.textDecoration = "none";
        kate.classList.remove('pointerButton');

        katie.style.color = "var(--bright-primary)";
        katie.style.textDecoration = "underline";
        katie.classList.add('pointerButton');

        katey.style.color = "var(--bright-primary)";
        katey.style.textDecoration = "underline";
        katey.classList.add('pointerButton');

        sessionStorage.setItem('bagenzoName', 'Kate');
    });
    
    katie.addEventListener('click', () => {
        title.textContent = "Katie Bagenzo";
        title.href = "index.html"
        tab.textContent = "About Katie";

        katie.style.color = "black";
        katie.style.textDecoration = "none";
        katie.classList.remove('pointerButton');

        kate.style.color = "var(--bright-primary)";
        kate.style.textDecoration = "underline";
        kate.classList.add('pointerButton');

        katey.style.color = "var(--bright-primary)";
        katey.style.textDecoration = "underline";
        katey.classList.add('pointerButton');

        sessionStorage.setItem('bagenzoName', 'Katie');
    });
    
    katey.addEventListener('click', () => {
        title.textContent = "Katey Bagenzo";
        tab.textContent = "About Katey";

        katey.style.color = "black";
        katey.style.textDecoration = "none";
        katey.classList.remove('pointerButton');

        katie.style.color = "var(--bright-primary)";
        katie.style.textDecoration = "underline";
        katie.classList.add('pointerButton');

        kate.style.color = "var(--bright-primary)";
        kate.style.textDecoration = "underline";
        kate.classList.add('pointerButton');

        sessionStorage.setItem('bagenzoName', 'Katey');
    });

    //replace spans with anchors
    kate_target.replaceWith(kate);
    katie_target.replaceWith(katie);
    katey_target.replaceWith(katey);

    // set button styles
    if (!targetName) {
        kate.style.color = "black";
        kate.style.textDecoration = "none";
        kate.classList.remove('pointerButton');
    }

    switch (targetName) {
        case 'Kate':
            kate.style.color = "black";
            kate.style.textDecoration = "none";
            katey.classList.remove('pointerButton');
            break;

        case 'Katie':
            katie.style.color = "black";
            katie.style.textDecoration = "none";
            katey.classList.remove('pointerButton');
            break;
            
        case 'Katey':
            katey.style.color = "black";
            katey.style.textDecoration = "none";
            katey.classList.remove('pointerButton');
            break;

        }
}